import { useTranslation } from "react-i18next";
import { useLogEvent } from "../hooks/useLogEvent";
import { device } from "../styles/stylesConfig";
import styled from "styled-components";

const Container = styled.section`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
`

const Info = styled.div`
    width: 500px;
    height: auto;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.50);
    padding: 3rem 3rem 2rem;
    overflow: hidden; 
    position: relative;
    cursor: default;

    @media ${device.sm} {
        width: 80%;
        margin: 0% 10%;
        padding: 3rem 2rem;
    } 



`

const Title = styled.h3`
    width: fit-content;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding-left: 5px;
    padding-bottom: 5px;
    margin-bottom: 2rem;
    position: relative;
    transition: all 0.5s ease-out;

    &::before {
        content: '';
        height: 5px;
        width: 50%;
        bottom: -10px;
        position: absolute;
        background-color: #D72929;
        transition: width 0.3s ease;
        border-radius: 50px;

        @media ${device.xs} {
            width: 30%;
            left: 0;
            right: 0;
            margin: auto;
        }   

    }

    @media ${device.sm} {
        font-size: 14px;
        letter-spacing: 4px;
    } 

`

const Description = styled.p`
    color: #333;
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    z-index: 4;
    position: relative;
    transition: all 0.5s ease-out;



    @media ${device.sm} {
        font-size: 12px;
    } 

`

const Close = styled.img`
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
    margin-left: 10px;
    margin-bottom: 7px;
`

const GoCorner = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50px;
    height: 50px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #D72929;
    border-radius: 1px 0px 1px 50px;
`

const MoreInfoButton = styled.button`
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    background-color: #D72929;
    margin-top: 20px;
    
    @media ${device.lg} {
        padding: 12px 20px;
    }
    
    @media ${device.md} {
        font-size: 10px;
    }

`


interface Props {
    showModal: {
        title: string;
        description: string;
    } | null
    setShowModal: React.Dispatch<React.SetStateAction<{ title: string, description: string } | null>>
}

export const ServiceModal = ({ showModal, setShowModal }: Props) => {

    const { handleLogEvent } = useLogEvent();

    const { t } = useTranslation();

    return (

        <Container id="modal" onClick={() => {
            setShowModal(null)
        }}>

            <button
                onClick={(e: any) => {
                    e.stopPropagation()
                }}
            >

                <Info>

                    <GoCorner
                        onClick={() => {
                            setShowModal(null)
                        }}
                    >

                        <Close
                            loading="eager"
                            src={`${process.env.REACT_APP_CDN}/cerrar.webp`}
                            alt='icono cruz cerrar'
                            id="cerrarModal"
                        />

                    </GoCorner>

                    <Title>{showModal?.title}</Title>

                    <Description>{showModal?.description}</Description>

                    <MoreInfoButton
                        className="utils__fade-in-fast-top"
                        onClick={async (e: any) => {

                            let message = `https://wa.me/+5491178999920?text=Hola Zandalazzini! Me contacto desde la web. Quiero más información acerca de sus servicios.`;

                            handleLogEvent('click_boton_mas_info_servicios');

                            e.target?.blur()

                            window.open(message, 'blank')

                        }}>
                        {t('general.info')}
                    </MoreInfoButton>

                </Info>

            </button>


        </Container>

    )
}
