import { ProductProps } from "../interfaces/TextsInterfaces";
import { device } from "../styles/stylesConfig";
import styled from "styled-components";
import { Button } from "./molecules/Button";
import { useLogEvent } from "../hooks/useLogEvent";
import { Input } from "./molecules/Input";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { ProductImageModal } from "./ProductImageModal";
import { useTranslation } from "react-i18next";
import InputSelect from "./molecules/InputSelect";

const Container = styled.section`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
    cursor: pointer;
`

const Info = styled.div`
    width: 700px;
    height: auto;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.50);
    padding: 3rem;
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.md} {
        width: 550px;
        padding: 2rem;
    } 

    @media ${device.sm} {
        width: 350px;
        padding: 3rem 2rem 2.5rem;
    } 

    @media ${device.xs} {
        width: 300px;
    } 

    & > button:last-of-type {
        padding: 10px 30px; 
        margin-top: 30px; 
        background-color: #24D466;
        
        @media ${device.sm} {
            padding: 10px 20px; 
        }

        @media ${device.xs} {
            margin-top: 20px; 
        }

    }

`

const Title = styled.h3`
    width: fit-content;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-left: 5px;
    /* padding-bottom: 5px;
    margin-bottom: 1rem; */
    position: relative;
    transition: all 0.5s ease-out;

    @media ${device.sm} {
        font-size: 14px;
        letter-spacing: 4px;
        
    } 

    /* @media ${device.xs} {
        margin-bottom: 0.5rem;
    }  */

`

const Description = styled.p`
    color: #333;
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    z-index: 4;
    position: relative;
    transition: all 0.5s ease-out;

    @media ${device.sm} {
        font-size: 12px;
    } 

`

const Close = styled.img`
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
    margin-left: 10px;
    margin-bottom: 7px;
`

const GoCorner = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50px;
    height: 50px;
    overflow: hidden;
    top: 0;
    right: 0;
    border-radius: 1px 0px 1px 50px;
`

const Image = styled.img`
    object-fit: cover;
    z-index: 2;
    border-radius: 5px;
    aspect-ratio: 16 / 10;
    max-height: 40vh;
    justify-self: center;
    align-self: center;
    margin: auto;

    @media ${device.sm} {
        width: 100%;
        max-height: 30vh;
    } 
`

const List = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;

    & > div {
        height: fit-content;
        width: 95%;
        position: relative;
    }

    @media ${device.sm} {
        & > div {
            position: relative;
        }
    }

    @media ${device.xs} {


    }

`

const NextArrow = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
`

const PrevArrow = styled(NextArrow)`
    transform: rotate(90deg);

`

interface Props {
    showModal: ProductProps | null
    setShowModal: React.Dispatch<React.SetStateAction<ProductProps | null>>
}

export const ProductModal = ({ showModal, setShowModal }: Props) => {

    const { handleLogEvent } = useLogEvent();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [typeSelected, setTypeSelected] = useState(0);

    const updateWindowWidth = () => {

        setWindowWidth(window.innerWidth);

    }

    const [showImageModal, setShowImageModal] = useState<null | string>(null);

    useEffect(() => {

        window.addEventListener('resize', updateWindowWidth)

        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }

    }, [])

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            type="button"
        >
            <PrevArrow src={`${process.env.REACT_APP_CDN}/flecha-menu.webp`} alt="arrow" />
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            type="button"
        >
            <NextArrow src={`${process.env.REACT_APP_CDN}/flecha-menu.webp`} alt="arrow" />
        </button>
    );

    const { t } = useTranslation();
    const data: any = t('products.data', { returnObjects: true });

    const getProductName = (string: any) => {

        if (string === 'Serras de fita' || string === 'Band saws') {
            return 'Sierras cintas'
        } else if (string === 'Serras de fita 5E.N' || string === 'Band saws 5E.N') {
            return 'Sierras cintas 5E.N'
        } else if (string === 'Serras descartáveis' || string === 'Disposable saws') {
            return 'Sierras descartables'
        } else if (string === 'Circulares múltiplas' || string === 'Multiple Circulars') {
            return 'Circulares múltiples'
        } else if (string === 'Circulares dividir e aparar' || string === 'Splitting & trimming circulars') {
            return 'Circulares partidores y despunte'
        } else if (string === 'Melamine Circulars') {
            return 'Circulares melamina'
        } else if (string === 'Aluminum Circulars') {
            return 'Circulares aluminio'
        } else if (string === 'Finger Joint Circulars') {
            return 'Circulares Finger Joint'
        } else if (string === 'Multicut' || string === 'Multicuts') {
            return 'Multicortes'
        } else if (string === 'Operadores de serra fita' || string === 'Band saw workers') {
            return 'Trabadores sierras cintas'
        } else if (string === 'Diamond wheels' || string === 'Rebolos de diamante') {
            return 'Muelas diamantadas'
        } else if (string === 'Borazon wheels' || string === 'Rebolos Borazon') {
            return 'Muelas borazón'
        } else if (string.includes('Máquina de moagem')) {
            return string.replace('Máquina de moagem', 'Máquina afiladora')
        } else if (string.includes('Sharpening machine')) {
            return string.replace('Sharpening machine', 'Máquina afiladora')
        }

        return string;
    }

    const getProductType = (string: any) => {

        if (string.includes('grain')) {
            return string.replace('grain', 'grano')
        } else if (string.includes('grão')) {
            return string.replace('grão', 'grano')
        } else if (string.includes('xícara')) {
            return string.replace('xícara', 'copa')
        } else if (string.includes('cup')) {
            return string.replace('cup', 'copa')
        } else if (string.includes('dente')) {
            return string.replace('dente', 'diente')
        } else if (string.includes('tooth')) {
            return string.replace('tooth', 'diente')
        } else if (string.includes('wood')) {
            return string.replace('wood', 'madera')
        } else if (string.includes('madeira')) {
            return string.replace('madeira', 'madera')
        } else if (string.includes('largura')) {
            return string.replace('largura', 'ancho')
        } else if (string.includes('width')) {
            return string.replace('width', 'ancho')
        } else if (string.includes('thickness')) {
            return string.replace('thickness', 'espesor')
        } else if (string.includes('espessura')) {
            return string.replace('espessura', 'espesor')
        }

        return string;
    }

    return (

        <Container id="modal" onClick={() => {
            setShowModal(null)
        }}>

            {showImageModal !== null && <ProductImageModal showModal={showImageModal} setShowModal={setShowImageModal} />}

            <button
                onClick={(e: any) => {
                    e.stopPropagation()
                }}
            >

                <Info>

                    <GoCorner
                        onClick={() => {
                            setShowModal(null)
                        }}
                    >

                        <Close
                            loading="eager"
                            src={`${process.env.REACT_APP_CDN}/cerrar-black.webp`}
                            alt='icono cruz cerrar'
                            id="cerrarModal"
                        />

                    </GoCorner>


                    <List>

                        <Slider
                            slidesToShow={1}
                            vertical={false}
                            infinite={showModal!.images.length > 1}
                            arrows={showModal!.images.length > 1}
                            swipe={false}
                            dots
                            prevArrow={<SlickArrowLeft />}
                            nextArrow={<SlickArrowRight />}
                        >

                            {showModal?.images[0] ? (

                                showModal?.images.map((item) => {
                                    return (

                                        <button
                                            key={item}
                                            onClick={(e: any) => {
                                                setShowImageModal(item)
                                                e.stopPropagation()
                                            }}
                                        >
                                            <Image
                                                src={`${process.env.REACT_APP_CDN}/products/${item}`}
                                                alt={item}
                                                key={item}
                                            />
                                        </button>
                                    )
                                })

                            ) : <></>

                            }

                        </Slider>

                    </List>

                    <Title>{showModal?.title}</Title>

                    {
                        showModal?.types && showModal?.types.length > 0 && (

                            <InputSelect
                                options={showModal.types}
                                onSelect={(input: any) => setTypeSelected(showModal.types.findIndex((value) => { return value === input }))}
                            />

                        )
                    }


                    {/* <Description>{showModal?.description}</Description> */}

                    {/* {
                        (showModal?.types && showModal?.types.length > 0) && (

                            <Input
                                label="Seleccione la medida"
                                type="select"
                                value={showModal?.types[typeSelected]}
                                labelTheme="dark"
                                theme="dark"
                                // incomplete={fieldIncomplete.brandId}
                                inputStyle={{ height: windowWidth > 600 ? undefined : 40, fontSize: windowWidth > 600 ? 14 : 12, textAlign: 'center', appearance: 'none', padding: 0 }}
                                labelStyle={{ left: 0, right: 0, fontSize: windowWidth > 600 ? undefined : 12 }}
                                style={{
                                    width: windowWidth > 600 ? 350 : '100%', alignSelf: 'center', justifySelf: 'center', marginLeft: 0,
                                }}
                                onChange={(e) => {

                                    const input = e.target.value

                                    setTypeSelected(showModal?.types.findIndex((value) => value === input))

                                }}
                            >

                                {
                                    showModal?.types.map((value) => {

                                        return (
                                            <option key={value} value={value}>{value}</option>
                                        )
                                    })
                                }

                            </Input>

                        )
                    } */}

                    <Button
                        type="primary"
                        text={t('products.action.button')}
                        onClick={() => {

                            let message = `https://wa.me/+5491178999920?text=Hola Zandalazzini! Quiero consultar por el producto: %0a %0a *${getProductName(showModal?.title)}* ${(showModal?.types && showModal?.types.length > 0) ? `%0a *Medida ${getProductType(getProductType(getProductType(showModal?.types[typeSelected])))}*` : ''} %0a %0a Aguardo a su respuesta. %0a ¡Muchas gracias!`;

                            handleLogEvent('click_boton_solicitar_por_whatsapp', { name: getProductName(showModal?.title) });

                            window.open(message, 'blank')

                        }}
                    />


                </Info>

            </button>


        </Container>

    )
}
