import { memo } from "react"
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { useLogEvent } from "../hooks/useLogEvent";
import { useAnimations } from "../effects/Effects";

const Container = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 5vh 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #111;
    z-index: 1;
`

const ImageLogo = styled.img`
    width: 100px;
    height: auto;

    @media ${device.md} {
        width: 80px;
    }

    @media ${device.sm} {
        width: 70px;
    }

    @media ${device.xs} {
        width: 60px;
    }

`


const GoHomeButton = styled.button`
    width: fit-content;
    height: fit-content;
    border-radius: 50px;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: rgba(152, 152, 152, 0.3);

`


const GoHomeImage = styled.img`
    width: 60px;
    height: auto;
    border-radius: 50px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.3);
    object-fit: contain;
    cursor: pointer;
    transition: all .3s ease;
    align-self: center;
    justify-self: center;   

    &:hover {
        transform: scale(1.05);
    }
    

    @media ${device.md} {
        width: 50px;
    }

    @media ${device.sm} {
        width: 45px;
        padding: 12px;
    }

    
    @media ${device.xs} {
        width: 40px;
        padding: 12px;
    }
`

const MemorizedFooter = () => {

    const { handleLogEvent } = useLogEvent();

    const { handleClickGoHome } = useAnimations();
    return (

        <Container>

            <ImageLogo
                className="utils__fade-in-fast-right"
                loading="eager"
                src={`${process.env.REACT_APP_CDN}/logo-white.webp`}
                alt="Glow Ink"
            />


            <GoHomeButton
                className="utils__fade-in-fast-left"
                onClick={() => {

                    handleLogEvent('click_boton_ir_arriba');

                    handleClickGoHome();
                }}
            >
                <GoHomeImage src={`${process.env.REACT_APP_CDN}/arrow-red.webp`} alt="Flecha roja" />
            </GoHomeButton>

        </Container>

    )
}

const Footer = memo(MemorizedFooter);

export default Footer;