
const handleClickGoSection = (id: string, trasition: 'smooth' | 'auto') => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: trasition });
    }
}

export const useAnimations = () => {

    // HOME

    const handleClickGoHome = () => { handleClickGoSection("home", "smooth") }

    // SERVICES

    const handleClickGoServices = () => { handleClickGoSection("services", "smooth") }

    // PRODUCTS

    const handleClickGoProducts = () => { handleClickGoSection("products", "smooth") }

    // ABOUT

    const handleClickGoAbout = () => { handleClickGoSection("about", "smooth") }


    return {
        handleClickGoHome,
        handleClickGoAbout,
        handleClickGoProducts,
        handleClickGoServices,
    }

}