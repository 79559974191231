import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextsConstants"
import { useTranslation } from "react-i18next"

const Container = styled.section`
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: -15vh;
    left: 0px;

    @media ${device.md} {
        top: -15vh;
    }

    @media ${device.sm} {
        top: -10vh;
    }
`

const ContentContainer = styled.div<{ img: string }>`
    width: 100%;
    max-width: 1400px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${device.lg} {
        width: 100%;
        min-height: unset;
        padding: 7rem 0rem;

        ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8); 
        z-index: 0;
    }
}
`

const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    & > h1 {
        font-weight: 500;
        font-size: 42px;
        color: #D72929;
        letter-spacing: 5px;
        line-height: 60px;
        margin-bottom: 70px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        position: relative;
        
        
        @media ${device.lg} {
            font-size: 32px;
            margin-bottom: 50px;
        }
        
        @media ${device.md} {
            font-size: 28px;
            text-align: center;
            margin-bottom: 30px;
        }   

        @media ${device.sm} {
            font-size: 20px;
            line-height: 30px;
        }
    }
    
`

const Info = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;
    position: relative;

    @media ${device.lg} {
        width: 100%;
    }
    
`

const Image = styled.div<{ img: string }>`
    width: 50%;
    min-height: 100%;
    z-index: 1;
    position: relative;
    margin-right: 3rem;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
    
`

const History = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    z-index: 1;

    @media ${device.lg} {
        width: 90%;
    }

    @media ${device.md} {
        align-items: center;
    }


    & > p {
        font-size: 16px;
        color: #dbdbdb;
        text-align: justify;
        font-weight: 400;
        margin-bottom: 2rem;

        @media ${device.xl} {
            margin-bottom: 1.5rem;
        }

        @media ${device.lg} {
            line-height: 25px;
        }
        
        @media ${device.md} {
            text-align: center;
            margin-bottom: 1rem;
        }

        @media ${device.sm} {
            font-size: 12px;
        }
    }

    & > p:last-of-type {
        margin-bottom: 0;
    }
    
`


const LogoBackground = styled.img`
    width: 300px;
    height: auto;
    position: absolute;
    bottom: -200px;
    right: -13vw;
    opacity: 0.1;
    transform: rotate(20deg);

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
`

export const About = () => {

    const { t } = useTranslation();
    const descriptions: any = t('about.descriptions', { returnObjects: true });
    return (

        <Container>

            <Target id="about" />

            <ContentContainer img={`${process.env.REACT_APP_CDN}/insumos.webp`}>

                <Title className='utils__fade-in-fast-top'>

                    <h1>{t('about.title')}</h1>

                </Title>

                <Info>

                    <Image className='utils__fade-in-fast-top' img={`${process.env.REACT_APP_CDN}/insumos.webp`} />

                    <History>

                        {
                            descriptions.map((value: string, index: number) => {
                                return <p className='utils__fade-in-fast-top' id={index.toString()} key={value}>{value}</p>
                            })
                        }

                    </History>

                    <LogoBackground
                        src={`${process.env.REACT_APP_CDN}/logo-white.webp`}
                        alt='logo'
                    />

                </Info>

            </ContentContainer>

        </Container>

    )
}
