import { useEffect, useState, Suspense, lazy } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import { useSelector } from "react-redux"
import Building from "../components/Building";
import styled from "styled-components";
import { getFullDate } from "../utils/Utilities";
import Footer from "../components/Footer";
import VideoSection from "../components/VideoSection";
import { LoadingScreen } from '../components/LoadingScreen';
import { ServiceModal } from "../components/ServiceModal";
import WhyUs from "../components/WhyUs";
import { About } from "../components/About";
import { Catalog } from "../components/Catalog";
import { ProductProps } from "../interfaces/TextsInterfaces";
import { ProductModal } from "../components/ProductModal";
import { useTranslation } from "react-i18next";
import WsFloatButton from "../components/WsFloatButton";

const Header = lazy(() => import("../components/Header"));
const Introduction = lazy(() => import("../components/Introduction"));
const Services = lazy(() => import("../components/Services"));
const Rights = lazy(() => import("../components/Rights"));

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

export const HomeScreen = () => {

    const { i18n } = useTranslation();

    const [showPreloader, setShowPreloader] = useState(true);

    const [showServiceModal, setShowServiceModal] = useState<{ title: string, description: string } | null>(null);

    const [showProductModal, setShowProductModal] = useState<ProductProps | null>(null);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const location = useLocation();

    const { loading, loadingAsync } = useSelector((state: any) => state.ui);

    const preloader = () => {

        if (loading === 0) {

            setTimeout(() => {
                setShowPreloader(false);
            }, 1750);

        }

    };

    useEffect(() => {

        preloader()

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, loading, i18n.language])

    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress passwordOk={passwordOk} setPasswordOk={setPasswordOk} />

    return (

        <Container>

            {showPreloader && <Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            {loadingAsync && <LoadingScreen />}

            <Suspense fallback={<Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}>

                {(showServiceModal !== null) && <ServiceModal showModal={showServiceModal} setShowModal={setShowServiceModal} />}

                {(showProductModal !== null) && <ProductModal showModal={showProductModal} setShowModal={setShowProductModal} />}

                <Header />

                <WsFloatButton />

                <Introduction />

                <Services setShowModal={setShowServiceModal} />

                <VideoSection />

                <Catalog setShowModal={setShowProductModal} />

                <About />

                <WhyUs />

                <Footer />

                <Rights />

            </Suspense>

        </Container>

    )
}
