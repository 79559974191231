import { memo, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { globalTexts } from "../constants/TextsConstants";
import { initialStateProduct } from "../utils/Jsons";
import { ProductProps } from "../interfaces/TextsInterfaces";
import { useTranslation } from "react-i18next";


const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
`

const InfoContainer = styled.img`
    height: 100%;
    max-height: 80vh;
    max-width: 70vw;
    flex-shrink: 0;
    background: #fff;
    object-fit: cover;
    transition: all 0.5s;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
    cursor: default;
    border-radius: 3px;

    @media ${device.lg} {
        max-width: 90vw;
    } 
`

const Close = styled.img`
    width: 20px;
    height: 20px;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 100;
`

interface Props {
    showModal: string | null
    setShowModal: React.Dispatch<React.SetStateAction<string | null>>
}

export const ProductImageModal = ({ showModal, setShowModal }: Props) => {

    const handleEscKeyPress = (event: any) => {
        if (event.key === 'Escape') {
            setShowModal(null)
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEscKeyPress);
        };
    }, []);

    return (

        <Container id="modal" onClick={(e: any) => {
            setShowModal(null)
            e.stopPropagation();
        }}>

            <button
                onClick={(e: any) => {
                    setShowModal(null)
                    e.stopPropagation();
                }}
            >

                <Close
                    loading="eager"
                    className='workModal__close-image'
                    src={`${process.env.REACT_APP_CDN}/cerrar.webp`}
                    alt='icono cruz cerrar'
                    id="cerrarModal"
                />

            </button>

            <InfoContainer
                loading="eager"
                src={`${process.env.REACT_APP_CDN}/products/${showModal}`}
                alt='imagen del producto'
                id="cerrarModal"
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            />

        </Container>

    )
}

const WorkModal = memo(ProductImageModal);

export default WorkModal;