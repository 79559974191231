import styled from "styled-components";
import { useLogEvent } from "../hooks/useLogEvent";
import { device } from "../styles/stylesConfig";
import { ProductProps } from "../interfaces/TextsInterfaces";
import { ProductsListView } from "./ProductsListView";
import { useTranslation } from "react-i18next";

const ContentContainer = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-self: center;
    z-index: 2;
    position: relative;
    padding: 5vh 0;
`

const Target = styled.div`
    height: 1px;
    width: 1px;
    position: absolute;
    top: -12vh;
`

const Title = styled.h2`
    width: 100%;
    font-weight: 500;
    font-size: 42px;
    color: #fff;
    letter-spacing: 5px;
    line-height: 60px;
    margin-bottom: 30px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    position: relative;
    text-align: center;


    @media ${device.lg} {
        font-size: 32px;
    }

    @media ${device.md} {
        font-size: 28px;
        text-align: center;
        margin-bottom: 20px;
    }   

    @media ${device.sm} {
        font-size: 20px;
        line-height: 30px;
    }
`

const MoreInfoButton = styled.button`
    color: #fff;
    width: fit-content;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    background-color: #D72929;
    margin-bottom: 50px;
    align-self: center;

    @media ${device.lg} {
        padding: 12px 20px;
        margin-bottom: 40px;

    }
    
    @media ${device.md} {
        margin-bottom: 30px;
        font-size: 10px;
    }

`

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<ProductProps | null>>
}

export const Catalog = ({ setShowModal }: Props) => {

    const { handleLogEvent } = useLogEvent();
    const { t } = useTranslation();
    const data: any = t('products.data', { returnObjects: true });

    return (

        <ContentContainer>

            <Target id="products" />

            <Title className="utils__fade-in-fast-top"> {t('products.title')}</Title>

            <MoreInfoButton
                className="utils__fade-in-fast-top"
                onClick={async (e: any) => {

                    let message = `https://wa.me/+5491178999920?text=Hola Zandalazzini! Me contacto desde la web. Quiero más información acerca de sus productos.`;

                    handleLogEvent('click_boton_mas_info_productos');

                    e.target?.blur()

                    window.open(message, 'blank')

                }}>
                {t('general.info')}
            </MoreInfoButton>

            <ProductsListView
                data={data}
                onClickProduct={(product: ProductProps) => {

                    setShowModal(product);

                }}

            />

        </ContentContainer>

    )
}
