import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa las traducciones directamente
import enTranslations from './constants/locals/en/translation.json';
import esTranslations from './constants/locals/es/translation.json';
import ptTranslations from './constants/locals/pt/translation.json';

// Inicializar i18next
i18n
  .use(initReactI18next) // Enlaza con React
  .init({
    fallbackLng: 'es', // Idioma por defecto
    debug: false, // Cambiar a false en producción
    interpolation: {
      escapeValue: false, // React ya hace la protección contra XSS
    },
    resources: {
      en: {
        translation: enTranslations,
      },
      es: {
        translation: esTranslations,
      },
      pt: {
        translation: ptTranslations,
      },
    },
  });

export default i18n;
