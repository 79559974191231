import React, { useState } from "react";
import styled from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { useTranslation } from "react-i18next";

// Contenedor del dropdown estilizado
const DropdownContainer = styled.div`
  position: relative;
  width: 350px;
  margin-top: 50px;

  @media ${device.sm} {
    width: 100%;
  }
`;

const InputLabel = styled.h2`
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    font-weight: normal;
    font-size: 13px;
    color: ${colors.primary_dark};
    
    @media ${device.sm} {
      font-size: 12px;
  }
`

// El botón que dispara el dropdown
const DropdownButton = styled.button`
  height: 46px;
  width: 100%;
  border-radius: 8px;
  box-shadow: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid #B7BABC;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  color: ${colors.dark};
  font-size: 14px;

  &:focus{
      outline: none;
      border: 1px solid ${colors.primary};
  }
    
  @media ${device.sm} {
    font-size: 12px;

  }

`;

// Lista desplegable
const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px; /* Altura específica */
  overflow-y: auto; /* Habilitar desplazamiento vertical */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Opción del dropdown
const DropdownItem = styled.div`
  font-size: 14px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  @media ${device.sm} {
    font-size: 12px;
  }

`;

interface Props {
  options: string[];
  onSelect: (e: string) => void;
}

export const InputSelect = ({ options, onSelect }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelect = (option: any) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const { t } = useTranslation();

  return (
    <DropdownContainer>
      <InputLabel>{t('products.input.label.choose.option')}</InputLabel>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {selectedOption || "Seleccionar..."}
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.map((option: any, index: any) => (
            <DropdownItem key={index} onClick={() => handleSelect(option)}>
              {option}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default InputSelect;
