import { styled } from "styled-components";
import { device } from "../styles/stylesConfig";
import { ProductProps } from "../interfaces/TextsInterfaces";
import { ProductCardItem } from "./ProductCardItem";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
`

const ProductsGrid = styled.div`
    width: fit-content;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 80px;

    @media ${device.xl} {
        grid-template-columns: repeat(3, 1fr);
        gap: 80px;
    }

    @media ${device.lg} {
        gap: 50px;
    }

    @media ${device.sm} {
        gap: 30px;
    }

    @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);
    }
   
`

interface Props {
    data: ProductProps[],
    onClickProduct: (product: ProductProps) => any;
}

export const ProductsListView = ({ data, onClickProduct }: Props) => {

    return (

        <Container>

            <ProductsGrid>

                {data.map((product) => (
                    <ProductCardItem key={product.title} data={product} onClick={() => { onClickProduct(product) }} />
                ))}

            </ProductsGrid>

        </Container>

    )
}
