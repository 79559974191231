import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextsConstants"
import { useTranslation } from "react-i18next"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.div`
    width: 100%;
    padding: 10vh 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    z-index: 1;

    @media ${device.md} {
        padding: 5vh 10% !important;
    }   

`

const Title = styled.h2`
    font-weight: 500;
    font-size: 42px;
    color: #fff;
    letter-spacing: 5px;
    line-height: 60px;
    margin-bottom: 70px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    position: relative;

    &::before {
        content: '';
        height: 5px;
        width: 110%;
        bottom: -20px;
        left: -5%;
        position: absolute;
        background-color: #D72929;
        transition: width 0.3s ease;
        border-radius: 50px;

        @media ${device.md} {
            bottom: -15px;
        }   

        @media ${device.xs} {
            width: 30%;
            left: 0;
            right: 0;
            margin: auto;
        }   

    }

    @media ${device.lg} {
        font-size: 32px;
    }

    @media ${device.md} {
        font-size: 28px;
        text-align: center;
        margin-bottom: 60px;
    }   

    @media ${device.sm} {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 50px;
    }
`

const DescriptionContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    position: relative;

    @media ${device.sm} {
        gap: 15px;
    }
`

const Description = styled.p`
    width: 100%;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 300;
    text-align: center;

    @media ${device.lg} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const MoreInfoButton = styled.button`
    color: #fff;
    width: fit-content;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    background-color: #D72929;
    margin-top: 50px;
    align-self: center;

    @media ${device.lg} {
        margin-top: 40px;
        
    }
    
    @media ${device.md} {
        padding: 15px 20px;
        margin-top: 30px;
    }

    @media ${device.sm} {
        margin-top: 30px;
        font-size: 10px;
    }

`

export const WhyUs = () => {

    const { handleLogEvent } = useLogEvent();

    const { t } = useTranslation();
    const descriptions: any = t('whyus.descriptions', { returnObjects: true });

    return (


        <Container>

            < Title className="utils__fade-in-fast-right" > {t('whyus.title')}</Title >

            <DescriptionContainer>

                {
                    descriptions.map((value: string) => (
                        <Description className="utils__fade-in-fast-top" key={value}>
                            {value}
                        </Description>
                    ))
                }

            </DescriptionContainer>

            <MoreInfoButton
                className="utils__fade-in-fast-top"
                onClick={async (e: any) => {

                    let message = `https://wa.me/+5491178999920?text=Hola Zandalazzini! Me contacto desde la web. Quiero más información acerca de sus productos.`;

                    handleLogEvent('click_boton_asesorate_con_nosotros');

                    e.target?.blur()

                    window.open(message, 'blank')

                }}>
                {t('whyus.button')}
            </MoreInfoButton>


        </Container >

    )
}

export default WhyUs;