import { styled } from "styled-components";
import { device } from "../styles/stylesConfig";
import { Button } from "./molecules/Button";
import { Typography } from "./atoms/Typography";
import { Icon } from "./atoms/Icon";
import { ProductProps } from "../interfaces/TextsInterfaces";

const ButtonCard = styled.button`
    width: 250px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.1s ease;
    align-self: center;
    justify-self: center;

    @media ${device.lg} {
        width: 220px;
        height: 400px;
    }

    @media ${device.sm} {
        width: 170px;
        height: 370px;
    }

    @media ${device.xs} {
        width: 130px;
        height: 300px;
    }

    hr {
        border: none;

        /* @media ${device.xs} {
            position: absolute;
            visibility: hidden;
        } */
    }

    img {
        width: 100% !important; 
        height: 100% !important; 
        object-fit: cover !important; 
        box-shadow: 0px 0px 12px 0px #ffffff50;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 1vh;
    }
`

const Info = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 20px;

    @media ${device.sm} {
        height: 70px;

        p {
            font-size: 12px;
        }
    }

    & > button {
        padding: 10px 30px; 
        margin-top: 15px; 
        background-color: #24D466;
        
        @media ${device.xl} {
            padding: 10px 20px; 
        }

        @media ${device.sm} {
            margin-top: 10px;
            padding: 7px 20px; 
            font-size: 12px;
        }

        @media ${device.xs} {
            padding: 5px 10px; 
            font-size: 10px;

        }

    }

`

const Title = styled.div`
    color: white;
    font-size: 14px;

        @media ${device.lg} {
        }

        @media ${device.sm} {

        }

        @media ${device.xs} {
  
        }

`

interface Props {
    data: ProductProps;
    onClick: () => void
}

export const ProductCardItem = ({ data, onClick }: Props) => {

    return (

        <ButtonCard onClick={() => { onClick() }}>

            <hr />

            <Icon
                size="XXL"
                src={`${process.env.REACT_APP_CDN}/products/${data.images[0]}`}
                className="utils__fade-in-fast-top"
            />

            <Info>

                <Typography
                    className="utils__fade-in-fast-top"
                    size='14px'
                    color="#fff"
                    style={{
                        textTransform: 'uppercase',
                    }}
                >
                    {data.title}
                </Typography>

            </Info>

        </ButtonCard>

    )
}
